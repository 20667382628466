<template>
  <div id="contato">
    <!-- Banner !-->
    <div
      class="flex items-center w-full h-38em md:h-45em bg-layout_green bg-cover bg-no-repeat bg-center pt-4 md:pt-8 pb-4 md:pb-8"
    >
      <div class="mx-5 md:mx-12 2xl:mx-96">
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-12 lg:col-span-7">
            <span class="text-white font-Montserrat text-xl">FALE COM A GENTE</span>
            <h1
              class="text-white font-Montserrat text-4xl md:text-6xl mt-3"
            >
              Entre em contato com a gente
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div id="info" class="py-12 md:py-28 mx-5 md:mx-12 2xl:mx-96">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 md:col-span-12">
            <h2
              class="text-gray-900 font-Montserrat font-semibold text-2xl md:text-4xl"
            >
              Preencha o formulário e entraremos em contato com você
            </h2>
            <form class="pt-12 pb-12">
                <div class="grid grid-cols-12 gap-6">
                    <div class="col-span-12 lg:col-span-4">
                        <input
                            v-model="form.nome"
                            type="text"
                            name="nome"
                            class="h-14 border border-gray-200 text-gray-800 px-4 block w-full shadow-sm sm:text-sm font-Montserrat border border-white rounded-xl focus:outline-none"
                            placeholder="Seu nome"
                        />
                    </div>
                    <div class="col-span-12 lg:col-span-4">
                        <input
                            v-model="form.email"
                            type="email"
                            name="email"
                            class="h-14 border border-gray-200 text-gray-800 px-4 block w-full shadow-sm sm:text-sm font-Montserrat border border-white rounded-xl focus:outline-none"
                            placeholder="Email"
                        />
                    </div>
                    <div class="col-span-12 lg:col-span-4">
                        <input
                            v-model="form.telefone"
                            type="phone"
                            name="phone"
                            class="h-14 border border-gray-200 text-gray-800 px-4 block w-full shadow-sm sm:text-sm font-Montserrat border border-white rounded-xl focus:outline-none"
                            placeholder="Telefone"
                        />
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-6 mt-8">
                    <div class="col-span-12 lg:col-span-12">
                        <textarea
                            v-model="form.mensagem"
                            class="h-44 border border-gray-200 text-gray-800 px-4 py-4 block w-full shadow-sm sm:text-sm font-Montserrat border-b-2 border borde-white rounded-xl focus:outline-none"
                            placeholder="Sua Mensagem"
                        ></textarea>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-6 mt-8">
                    <div class="col-span-12 lg:col-span-12 justify-self-center">
                        <button
                            type="button"
                            @click="sendMensagem"
                            class="border-2 border-primary bg-primary text-base text-white py-3 px-3 font-Montserrat rounded mt-8 w-full md:w-60"
                        >
                            Enviar Mensagem
                        </button>
                    </div>
                </div>
            </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "contato",
  data() {
    return {
      form: {
        nome: "",
        email: "",
        telefone: "",
        mensagem: "",
      },
    };
  },
  methods: {
    async sendMensagem(){
      axios.post('http://localhost:9367/api/leadsite',this.form);
      this.form = {
        nome: "",
        email: "",
        telefone: "",
        mensagem: "",
      };
      alert("Mensagem enviada com sucesso!!");
    }
  },
};
</script>
